/*----------------------------------------------
	ページの一番上に戻る
---------------------------------------------*/

let pageTop = () => {
	//スクロール量を取得する関数
	function getScrolled() {
		return ( window.pageYOffset !== undefined ) ? window.pageYOffset: document.documentElement.scrollTop;
	}

	//トップに戻るボタンの要素を取得
	let topButton = document.getElementById( 'js_page-top' );

	//ボタンの表示・非表示
	window.addEventListener ('scroll', () => {
		( getScrolled() > 500 ) ? topButton.classList.add( 'is_fade-in' ): topButton.classList.remove( 'is_fade-in' );
	});

	//トップに移動する関数
	function scrollToTop() {
	  let scrolled = getScrolled();
	  // window.scrollTo( 0, Math.floor( scrolled / 1.1 ) );
		window.scrollTo( 0, parseInt( scrolled / 1.1 ) );
	  if ( scrolled > 0 ) {
	    window.setTimeout( scrollToTop, 5 );
	  }
	};

	//イベント登録
	topButton.onclick = function() {
	  scrollToTop();
	};
};

pageTop();
